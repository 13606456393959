import {
  Divider,
  isWeb,
  ThemeName,
  useMedia,
  XStack,
  YStack,
  YStackProps,
} from '@mythical/ui'
import { ButtonLink, ButtonLinkProps } from 'app/components/primitive/Button'
import {
  IconBell,
  IconChat,
  IconHeart,
  IconHome,
  IconLogOut,
  IconPlaylist,
  IconSearch,
  IconUser,
} from 'app/components/primitive/Icon'
import { IconProps } from 'app/components/primitive/Icon/IconProps'
import { IconLeaderboard } from 'app/components/primitive/Icon/icons/IconLeaderboard'
import { IconMerch } from 'app/components/primitive/Icon/icons/IconMerch'
import Version from 'app/components/primitive/Version/index'
import { UserInfo } from 'app/components/user/UserInfo'
import { useRouter } from 'app/hooks/use-router'
import { useStores } from 'app/store'
import { isIOS } from 'app/utils/constants'
import getEnv from 'app/lib/env-getter'
import React, { NamedExoticComponent } from 'react'
import { useRouter as useSolitoRouter } from 'solito/router'

const secondaryLinks = [
  { href: '/contact', label: 'Contact' },
  { href: '/pages/faqs', label: 'Faqs' },
  { href: '/perks', label: 'Perks', hidden: isIOS },
  { href: '/pages/privacy', label: 'Privacy Policy' },
  { href: '/pages/terms', label: 'Terms of Use' },
]

const links = [
  {
    label: 'Home',
    reset: true,
    icon: IconHome,
    match: ['/', '/homescreen'],
  },
  {
    label: 'Merch & Collectibles',
    href: '/merch',
    icon: IconMerch,
  },
  {
    label: 'Discord Community',
    href: 'https://mythicalsociety.memberful.com/account/discord/authorize',
    icon: IconChat,
    external: true,
  },
  {
    label: 'All Series',
    href: '/content',
    icon: IconPlaylist,
  },
  {
    label: 'Your favorites',
    href: '/favorites',
    icon: IconHeart,
    loggedIn: true,
  },
  {
    label: 'Leaderboards',
    href: '/leaderboards',
    icon: IconLeaderboard,
    loggedIn: true,
  },
]

type SidebarProps = {
  onNavigate?: () => void
  buttonTheme?: ThemeName
} & YStackProps

const Sidebar = ({
  onNavigate,
  buttonTheme = 'secondary9',
  maxWidth = 330,
  ...props
}: SidebarProps) => {
  const router = useRouter()
  const solitoRouter = useSolitoRouter()
  const path = '/' + router?.pathname.split('/')[1] //TODO: add support for native
  const { userStore } = useStores()
  const loggedIn = !!userStore?.user?.id
  const logInUrl = userStore?.generateLoginUrl() || '/'
  const media = useMedia()
  const userTier = userStore?.user?.tier

  const sidebarLinks = [...links]

  if (getEnv() !== 'production') {
    sidebarLinks.push({
      label: 'Mock Login',
      href: '/mock-login',
      icon: IconUser,
    })
  }

  return (
    <YStack
      space="$2.5"
      py="$4"
      w="100%"
      $gtXs={{
        pt: '$2',
      }}
      {...props}
    >
      <UserInfo px="$3" py="$4" maxWidth={330} mx="auto" />
      {loggedIn && <Divider />}
      <YStack
        flexGrow={1}
        space="$3"
        width="100%"
        mx="auto"
        maxWidth={maxWidth}
      >
        {media.md && (
          <YStack space="$3.5">
            {loggedIn && userTier !== '3rd Degree' && !isIOS && (
              <SidebarButton onNavigate={onNavigate} centered href="/">
                Upgrade for more
              </SidebarButton>
            )}
            {!loggedIn && (
              <SidebarButton
                onNavigate={onNavigate}
                centered
                href={isWeb ? logInUrl : '/login'}
                $gtMd={{
                  display: 'none',
                }}
                auth={!isWeb}
              >
                Log in
              </SidebarButton>
            )}
            <SidebarButton
              onNavigate={onNavigate}
              href="/search"
              buttonIcon={IconSearch}
              theme="secondary"
              color="$primary6"
              isActive={path === '/search'}
            >
              Search
            </SidebarButton>
          </YStack>
        )}
        <YStack space="$1.5">
          {sidebarLinks
            .filter((link) => (link.loggedIn ? loggedIn : true))
            .map((link) => (
              <SidebarButton
                onNavigate={() => {
                  if (link.reset && !isWeb) {
                    solitoRouter?.push('/home')
                  }

                  if (link.reset && isWeb) {
                    router?.push(loggedIn ? '/homescreen' : '/')
                  }

                  onNavigate?.()
                }}
                key={link.label}
                href={link?.href || '#'}
                buttonIcon={link.icon}
                external={link.external}
                theme="secondary"
                color="$primary6"
                isActive={path === link.href || link.match?.includes(path)}
              >
                {link.label}
              </SidebarButton>
            ))}
        </YStack>

        {loggedIn && (
          <YStack space="$1.5">
            <SidebarButton
              onNavigate={onNavigate}
              href="/user/profile"
              external
              buttonIcon={IconUser}
              color="$secondary6"
              theme="secondary"
              isActive={path === '/user'}
              auth
            >
              Profile
            </SidebarButton>
            {!isWeb && (
              <SidebarButton
                onNavigate={onNavigate}
                href="/notification-settings"
                buttonIcon={IconBell}
                color="$secondary6"
                theme="secondary"
                isActive={path === '/notification-settings'}
              >
                Notification Settings
              </SidebarButton>
            )}

            {loggedIn && (
              <SidebarButton
                onNavigate={() => {
                  userStore.logout()
                  setTimeout(() => {
                    onNavigate?.()
                  }, 500)
                }}
                href={isWeb ? '/api/auth/logout' : '/'}
                buttonIcon={IconLogOut}
                target="_self"
                color="$secondary6"
                theme="secondary"
              >
                Log out
              </SidebarButton>
            )}
          </YStack>
        )}
      </YStack>
      <XStack
        maxWidth={330}
        mx="auto"
        mt="auto"
        flexWrap="wrap"
        pt="$2"
        w="100%"
        jc="space-evenly"
      >
        {secondaryLinks
          .filter((l) => !l.hidden)
          .map((link) => (
            <ButtonLink
              key={link.label}
              href={link.href}
              size="$2"
              theme={buttonTheme}
              fontSize="$2"
              onPressCustom={onNavigate}
            >
              {link.label}
            </ButtonLink>
          ))}
      </XStack>
      <YStack maxWidth={330} mx="auto" mt="auto" w="100%">
        <Version />
      </YStack>
    </YStack>
  )
}

export default Sidebar

type SidebarButtonProps = {
  buttonIcon?: NamedExoticComponent<IconProps>
  children: React.ReactNode
  centered?: boolean
  external?: boolean
  isActive?: boolean
  onNavigate?: () => void
} & ButtonLinkProps

const SidebarButton = ({
  children,
  buttonIcon,
  color,
  centered,
  onNavigate,
  external,
  isActive,
  ...props
}: SidebarButtonProps) => {
  const onPress = () => {
    onNavigate && onNavigate()
  }

  const Icon = buttonIcon

  return (
    <ButtonLink
      w="100%"
      justifyContent={centered ? 'center' : 'flex-start'}
      ai="center"
      {...(external && { target: '_blank' })}
      {...props}
      color={color}
      {...(Icon && {
        icon: <Icon color={color || '$primary6'} size={24} />,
      })}
      bg={isActive ? '$backgroundHover' : '$background'}
      onPressCustom={onPress}
    >
      {children}
    </ButtonLink>
  )
}
